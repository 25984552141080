export const mappedRatingWithQuestionType = {
  Essential: 1,
  Basic: 2,
  Medium: 3,
  High: 4,
};

export const mappedCreditWithOpportunityType = {
  Essential: 0,
  Basic: 1,
  Medium: 2,
  High: 3,
};
export const frameworkExportParams = [
  "Plan Id",
  "User Id",
  "Address",
  "Acres",
  "Country",
  "Plan Year",
  "State",
  "Zipcode",
  "Longitude",
  "Latitude",
  "Crop Name",
  "Additional Farm Area",
  "Irrigation",
  "Partner",
  "Plan Created",
  "Plan Modified",
  "Short Label",
  "Outcome Name",
  "Question Id",
  "Question Text",
  "Outcome Score",
  "Outcome Type",
  "Driver Name",
  "Driver Score",
  "Unit",
  "Answer Id",
  "Answer",
  "SOA ID",
  "Question Labels",
  "Answer Created",
  "Answer Modified",
];
export const descriptionText = {
  Question: {
    High: "If user responds yes to this question they will receive a score of HIGH",
    Medium:
      "If user responds yes to this question they will receive a score of MEDIUM",
    Basic:
      "If user responds yes to this question they will receive a score of BASIC",
  },
  Opportunity: {
    Medium: "Opportunity for MEDIUM score",
    Basic: "Opportunity for BASIC score",
    Essential:
      "If user responds no to all above they will receive a score of ESSENTIAL and be shown this opportunity",
  },
};
export const questionPriority = ["High", "Medium", "Basic", "Essential"];
export const strengthDescription =
  "If user receives this score for the Driver, they will see this statement as “What you’re doing well” in their Benchmark report";

export const OUTCOME = {
  TIERED: "Tiered",
  MANAGEMENT_PRACTICE: "Management Practice",
};

export const outcomeType = [
  {
    id: "tiered",
    type: OUTCOME.TIERED,
  },
  {
    id: "practice",
    type: OUTCOME.MANAGEMENT_PRACTICE,
  },
];
export const USERROLE = {
  ADMIN: process.env.REACT_APP_ADMIN,
  SUPERADMIN: (process.env.REACT_APP_SUPER_ADMIN || "").split(","),
};

export const SAVE_TEMPLATE_MODAL_DESCRIPTION =
  "You can save the annual changes to the Sustainability Alliance Initiative Standard by making this template the new Main Template. All Frameworks created after clicking Save will have the same questions and configurations as this Template.";

export const SAVE_TEMPLATE_MODAL_WARNING =
  "You are about to make this version of the Template the Main Template for all future Frameworks created. This cannot be undone.";

export const QUESTION_TYPE = ["Yes/No", "Numeric/Text Input", "Multi-Select"];

export const DISABLED_CARD_SHADE =
  "linear-gradient(.35turn, #929f9b 10%, #c8c8e2)";

export const FONT = {
  fontWeight: 600,
  fontSize: 16,
};

export const PARTNER_LOCK_STATUS = {
  locked:
    "Currently in Locked status, message will be displayed to mobile users",
  unlocked: "Toggle to Lock the Partner's dataset",
  inProgress:
    "The Partner lock is in progress, click Save and refresh the page to continue.",
};

export const DEFAULT_COUNTRY = {
  code: "US",
  id: "840",
  name: "United States",
  locale: "en",
};
export const DEFAULT_PAGE_SIZE = 50;
export const CACHE_TIME = 100;

export const CROPS_DATA_EMPTY_WARNING = {
  title: "No crops available for selected country",
  content:
    "There are no crops available for this selection from Cropwise Base. Please work with the Base team to resolve.",
};
export const COUNTRY_NOT_SUPPORTED = {
  title: "Country not supported",
  content: "This country is not supported from Cropwise Base.",
};
export const FETCH_CROPS_FAILURE = {
  title: "Unable to fetch crops",
  content: "Unable to fetch crops. Please try again.",
};
export const DELETE_PRACTICE_QUESTION_ERROR_MSG =
  "Unable to delete question. Please try again later.";

export const DELETE_ITEM_POPUP_PARAMS = {
  errorMessage: "Unable to delete question. Please try again later.",
  popupTitleText: "Are you sure you want to delete this question?",
  popupContent: "This action cannot be undone.",
};
export const NO_CROPS_ENABLED_NOTE =
  "No crops are enabled. Use the Navigation panel to select the Crops tab, then enable crops for this country.";

export const createFrameworkPageUrl = "/create-new-framework";
export const editFrameworkPageUrl = "/edit-framework";
export const cloneFrameworkPageUrl = "/clone-framework";

export const TEMPLATE_NAME = "Sustainability Alliance Initiative Standard";
