export enum IrrigationType {
  Neither = "NEITHER",
  Irrigation = "IRRIGATION",
  Rainfed = "RAINFED",
}
export enum AdditionalAcres {
  Neither = "NEITHER",
  Yes = "YES",
  No = "NO",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortKey {
  ID = "ID",
  NUMBER = "NUMBER",
  RATING = "RATING",
  NAME = "NAME",
}

export enum StatusType {
  DRAFT = "draft",
  IN_REVIEW = "in_review",
  PUBLISHED = "published",
  ERRORED = "errored",
}

export enum FrameworkPartnerStatusType {
  LOCKED = "locked",
  INPROGRESS = "inprogress",
  UNLOCKED = "unlocked",
}

export interface PageDetails {
  currentPageNumber: number;
  size: number;
}

export interface CropDataType {
  name: string;
  yeildUnit: string;
  status: React.ReactNode;
}
export type AnyKeyValuePair = {
  [key: string]: any;
};

export enum FrameworkType {
  COUNTRY = "Country",
  PARTNER = "Partner",
}
export const UMBRELLA_PARTNER = "umbrella";
