export const BREADCRUMB_KEYS: Record<PageFlow, (keyof CommonProps)[]> = {
  mainFlow: [
    "frameworks-by-country",
    "template",
    "outcome",
    "driver",
    "questions",
  ],
  editOutcomeFlow: [
    "frameworks-by-country",
    "template",
    "outcome",
    "editOutcome",
  ],
  editDriverFlow: [
    "frameworks-by-country",
    "template",
    "outcome",
    "driver",
    "editDriver",
  ],
  editFrameworkFlow: ["edit-framework", "editPartner"],
};
export type PageFlow =
  | "mainFlow"
  | "editOutcomeFlow"
  | "editDriverFlow"
  | "editFrameworkFlow";

export interface CommonProps {
  "frameworks-by-country"?: string;
  "frameworks-by-partner"?: string;
  template?: string;
  outcome?: string;
  driver?: string;
  questions?: number;
  editOutcome?: string;
  editDriver?: string;
  createFramework?: string;
  "edit-framework"?: string;
  cloneFramework?: string;
  editPartner?: string;
}

export type Framework = {
  id: string;
  name: string;
  country: string;
  status: string;
  version: string;
  otherCountries?: string[];
};
