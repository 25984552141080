import { useAuth } from "lib/auth/exports";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getActiveModules } from "shared/utils/common-methods";
import { Sidebar } from "syngenta-digital-cropwise-react-ui-kit";
import "./sidebar.less";

export const SidebarMenu: React.FC = (props) => {
  const navigate = useNavigate();
  const { routePaths } = useAuth();
  const location = useLocation();
  const routes = getActiveModules(routePaths);
  const routesMap = Object.keys(routes);
  const [active, setActive] = useState("");
  const pathName = location.pathname.split("/");

  //TODO: need to remove this after framework url change
  useEffect(() => {
    const getActiveRoute = () => {
      const currentPath =
        location.pathname.split("/").pop()?.toUpperCase() || "";
      const matchingRoute = routesMap.find(
        (route) => route.toUpperCase() === currentPath
      );
      if (!matchingRoute) {
        if (currentPath.includes("FRAMEWORKS")) {
          return "FRAMEWORKS-BY-COUNTRY";
        } else if (currentPath.includes("REPORTS")) {
          return "REPORTS-COUNTRY";
        }
      } else {
        return matchingRoute;
      }
    };
    const getRoute = getActiveRoute();
    setActive(getRoute!);
  }, [location.pathname]);

  return (
    <Sidebar
      data-cy="sidebar"
      theme="dark"
      selectedKeys={[active]}
      collapsedMenu={true}
      // @ts-ignore
      menuItems={routesMap.map((route) => ({
        key: route,
        label: (routes as any)[route].name,
        icon: (
          <img
            data-cy={`${route.toLowerCase()}-sidebar-icon`}
            className={"sidebar-icon active"}
            alt={route.toLowerCase()}
            src={require(`../../assets/images/${route.toLowerCase()}.svg`)}
          />
        ),
        onClick: async (e: any) => {
          setActive(route);
          navigate(`/app/${route.toLowerCase()}`);
        },
      }))}
    />
  );
};
